import React from "react";

import Layout from "../components/layout";

import { graphql } from "gatsby";
import Img from "gatsby-image";

import { css } from "@emotion/core";

import HEAD from "../components/head";
import logo from "../images/redapple_Final_Lightgray.png";

const ContactForm = ({ data }) => (
  <Layout showHome>
    <HEAD
      title="Contact us - thanks for your message"
      keywords={[`red apple solutions contact us`, `education`, `red`, `apple`]}
    />
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          //height: 100%;
        `}
      >
        <Img
          title="Chalkboard"
          alt="Chalkboard background image"
          fluid={data.chalkboard.childImageSharp.fluid}
          css={css`
            position: inherit !important;
          `}
        />
        {/*
      <div
        //bg with paralax transform
        //data-parallax="true"
        css={css`
          height: 90vh;
          overflow: hidden;
          position: relative;
          background: url(${bg_image});
          background-position: 50%;
          background-size: cover;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          background-color: lightgray;
        `}
      >
      */}
        <div
          className="container"
          css={css`
            margin-top: 60px;
          `}
        >
          <div className="row">
            <div className="col-md">
              <img
                className="float-right"
                css={css`
                  width: 100%;
                  max-width: 200px;
                  min-width: 200px;

                  padding: 15px;
                  @media (max-width: 992px) {
                    float: none !important;
                  }
                `}
                src={logo}
                alt="Red Apple Solutions - brand"
              />
            </div>
            <div className="col-lg-8 mr-auto">
              <div
                css={css`
                  //background-color: white;
                `}
              >
                <div
                  css={css`
                    padding: 25px;
                    background-color: white;
                    border-radius: 2px;
                    box-shadow: 0 6px 12px 5px rgba(0, 0, 0, 0.15);
                  `}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <p>
                          Thank you for contacting us. We will get back to you
                          as soon as possible!
                        </p>
                      </div>
                    </div>
                    <div>
                      <a href="/">Return</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactForm;

export const query = graphql`
  query {
    chalkboard: file(relativePath: { eq: "chalkboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
